import React from 'react';
import ServiceTemplate from 'templates/ServiceTemplate';
import GoldReflections from 'images/gold-reflections.jpg';

const bullets = [
  'Align to the opportunity',
  'Remove the barriers',
  'Deliver 10x returns',
];

const CorporateDevelopmentPage = () => (
  <ServiceTemplate
    bullets={bullets}
    excludeUrl="/corporate-development"
    imagePath={GoldReflections}
    metaDescription="OutPerform Corporate Development and Operational Services - Build the right plan and execute"
    outText="Perform"
    title="Corporate development and operational services"
  >
    <div>
      <h4 className="tc ttu">
        Winning requires <strong>both</strong> building the right plan and strong execution
      </h4>

      <p className="intro--paragraph">
        Too often good companies underachieve while others break out and enjoy the upside of
        sustained growth and market leadership. The winners are the teams who are able to both
        target the right opportunities and execute consistently. While this sounds simple,
        most struggle to connect the dots and keep them connected as the company grows and
        the market evolves.
      </p>

      <p className="intro--paragraph">
        We have been involved with hundreds of growth companies. We know how to set the
        direction and make it happen. We know how to adjust and evolve as your company and
        market mature.  We can identify when the problem is operational versus strategic.
        We can distinguish issues with your team versus your market or product. We know
        the playbooks for market entry, rapid growth and global scale. We understand investors,
        strategic buyers and corporate development. We can put you on the path to winning.
      </p>

      <div className="deliverables pv4">
        <div className="container">
          <h3 className="white">
            Specific service offerings include:
          </h3>

          <div className="db flex-l">
            <div className="bucket w-100 w-third-l pb3 pb0-ns pr0 pr3-l">
              <h5>
                Performance Review &amp; Root Cause
              </h5>

              <p>
                Understand execution and results trends.
              </p>

              <p>
                Identify barriers to more consistent and/or rapid growth.
              </p>

              <p>
                Recommend focus areas and action program.
              </p>
            </div>

            <div className="bucket w-100 w-third-l pb3 pb0-ns ph0 ph3-l">
              <h5>
                Market Opportunity &amp; Strategy Tune Up
              </h5>

              <p>
                Identify new market/segment opportunities for the company.
              </p>

              <p>
                Rate, rank, size and assess&mdash;including operational and strategic implications.
              </p>

              <p>
                Propose priorities and roadmap for execution and corporate development.
              </p>
            </div>

            <div className="bucket w-100 w-third-l pb3 pb0-ns pl0 pl3-l">
              <h5>
                COO &amp; Operational Services
              </h5>

              <p>
                Articulate operational plan including goals, metrics and initiatives.
              </p>

              <p>
                Identify key actions, resources and systems needed to deliver.
              </p>

              <p>
                Mobilize the team to align and execute.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ServiceTemplate>
);

export default CorporateDevelopmentPage;
